import { template as template_7e84764a476142f08dc30c56bb11955a } from "@ember/template-compiler";
const FKLabel = template_7e84764a476142f08dc30c56bb11955a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
