import { template as template_09958b9154a74226a327b07e2291abb9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_09958b9154a74226a327b07e2291abb9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
